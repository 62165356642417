@use "./config" as *;

.custom-form {
  position: relative;
  z-index: var(--z1);
  flex-wrap: wrap;
  @include flex-config(flex, null, space-between, null);

  .flex20,
  .flex25,
  .flex33,
  .flex50,
  .flex100 {
    flex-basis: 100%;
    width: 100%;
  }

  .form-group {
    margin: var(--half-space) 0;
  }

  .form-element {
    position: relative;
    text-align: left;
  }

  .select-box {
    position: relative;
    &::after {
      position: absolute;
      content: "\e809";
      font-family: $font-icon;
      font-size: 0.9rem;
      top: 4.4rem;
      right: 1rem;
      z-index: var(--z-negative);
    }
  }

  .control-label {
    font-size: 1.2rem;
    font-family: $font-regular;
    color: var(--kabaddi-accent-color);
    // padding: 0 1rem;
  }

  .input-limit {
    padding-right: 7rem;
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 4rem;
    font-size: 1.1rem;
    border: 0.1rem solid rgba(var(--black-color-rgb), 0.05);
    background-color: rgba(var(--grey-color-rgb), 0.5);
    padding: 0 var(--full-space);
    // font-family: $font-medium;
    color: var(--black-color);
    border-radius: var(--half-radius);
    margin-top: 0.8rem;
    // @include placeholder(rgba(var(--kabaddi-accent-color-rgb), 0.7), $font-regular, 1.1rem);
  }
  textarea {
    height: 100%;
    padding: var(--half-space);
  }
  select {
    cursor: pointer;
  }

  .character-count {
    text-align: right;
    // font-family: $font-medium;
    color: var(--white-color);
    font-size: 1rem;
    span {
      font-size: 1rem;
    }
  }

  input[type="date"] {
    -webkit-appearance: none;
  }

  input[type="checkbox"] {
    display: none;
    + label {
      position: relative;
      cursor: pointer;
      color: var(--white-color);
      font-size: 1.4rem;
      margin: 0;
      padding-left: 2rem;
      &:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 0.1rem solid var(--white-color);
        box-shadow: 0 0.1rem 0.2rem rgb(var(--black-color-rgb), 5%),
          inset 0 -1.5rem 1rem -1.2rem rgb(var(--black-color-rgb), 5%);
        padding: 0.6rem;
        position: absolute;
        left: 0;
        cursor: pointer;
        top: 0.3rem;
      }
    }

    &:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 0.4rem;
      left: 0.5rem;
      width: 0.5rem;
      height: 1rem;
      border: solid var(--white-color);
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}
.login,
.register {
  text-align: center;
  .btn-login,
  .btn-register {
    // background-color: var(--kabaddi-accent-color);
    // color: var(--white-color);
    // border-radius: 5rem;
  }
}
.disclaimer-section {
  color: var(--kabaddi-accent-color);
  font-family: "Montserrat-regular", sans-serif;
  .disclaimer {
    margin-bottom: 1rem;
  }
}
.btn-pwd-toggle {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 3rem;
  right: 3rem;

  .password-sign {
    &::before {
      content: "\e877";
    }
  }

  &[aria-expanded="true"] {
    .password-sign {
      &::before {
        content: "\e80b";
      }
    }
  }
}

.password-sign,
.icon-info-circled {
  position: relative;
  width: 100%;
  height: 100%;
  @include flex-config(flex, null, center, center);

  &::before {
    position: absolute;
    font-family: $font-icon;
    font-size: 1.2rem;
    color: rgba(var(--kabaddi-accent-color-rgb), 0.5);
  }
}

.tooltip {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 2.9rem;
  right: 0.5rem;
  // display: inline-block;
  // position: relative;
  // top: -0.4rem;
  // right: 0.5rem;
  color: var(--kabaddi-accent-color);

  .tooltiptext {
    display: none;
    position: absolute;
    width: 25rem;
    padding: var(--half-space);
    font-size: 1rem;
    color: var(--black-color);
    text-align: center;
    top: 3rem;
    right: 0;
    background: var(--white-color);
    border: 0.1rem solid var(--kabaddi-accent-color);
    z-index: var(--z-index1);
  }

  &:hover {
    .tooltiptext {
      display: block;
    }
  }

  .btn-pwd-tip {
    position: relative;
    width: 100%;
    height: 100%;

    .icon-info-circled {
      &::before {
        content: "\e876";
        font-size: 1.2rem;
        color: var(--light-blue-color);
      }
    }
  }
}

.align-radio {
  @include flex-config(flex, null, center, center);

  .radio-box-wrap {
    margin-top: 0;
    margin-left: 1.5rem;
    @include flex-config(flex, null, null, center);
    .radio-box {
      margin: 0 var(--half-space);
    }
  }
}

.radio-box-wrap {
  height: 4rem;
  margin-top: 0.6rem;
  @include flex-config(flex, null, flex-start, center);

  .radio-box {
    margin: 0 var(--half-space);

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .radio-container {
      display: block;
      position: relative;
      padding-left: 2.5rem;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 1.2rem;
      color: var(--kabaddi-accent-color);
      font-family: $font-regular;
      margin-bottom: 0;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 50%;
        left: 0;
        transform: translateX(-50%);
        &:checked {
          ~ .checkmark {
            background-color: var(--white-color);
            border: 0.1rem solid var(--kabaddi-primary-color);
            &:after {
              display: block;
            }
          }
        }
      }
      .checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 1.6rem;
        width: 1.6rem;
        border: 0.1rem solid rgba(var(--kabaddi-accent-color-rgb), 0.6);
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 50%;
          background: var(--kabaddi-primary-color);
        }
      }
    }
  }
}
// .reg-gender {
//     .radio-box-wrap {
//         @include flex-config(null, null, space-around, null);
//     }
// }

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2rem;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .checkmark {
        background-color: var(--kabaddi-primary-color);
        border-color: var(--kabaddi-primary-color);
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    left: -2rem;
    top: 0;
    height: 1.6rem;
    width: 1.6rem;
    border: 0.1rem solid rgba(var(--kabaddi-accent-color-rgb), 0.6);
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 0.4rem;
      top: 0.2rem;
      width: 0.4rem;
      height: 0.7rem;
      border: solid var(--kabaddi-accent-color);
      border-width: 0 0.1rem 0.1rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.errordiv {
  font-family: $font-regular;
  font-size: 1.2rem;
  color: var(--kabaddi-accent-color);
}

@media screen and (min-width: $tablet-min-width) {
  .custom-form {
    .flex50 {
      flex-basis: calc(50% - var(--half-space));
      width: calc(50% - var(--half-space));
    }
    .flex33 {
      flex-basis: calc(33% - var(--half-space));
      width: calc(33% - var(--half-space));
    }
    .flex25 {
      flex-basis: calc(25% - var(--half-space));
      width: calc(25% - var(--half-space));
    }
    .flex20 {
      flex-basis: calc(20% - var(--half-space));
      width: calc(20% - var(--half-space));
    }
  }
}

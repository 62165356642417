@use "./config" as *;
@use "forms";

.waf-contact-us {
  margin: calc(2 * var(--full-space)) auto;
  .contact-form {
    .right-form-wrapper,
    .left-form-wrapper {
      width: 100%;
    }
    .control-label {
      color: var(--kabaddi-secondary-color);
      font-family: $font-bold;
    }
    input,
    textarea {
      background-color: rgba(var(--input-bg-color-rgb), 0.05);
      height: 4.5rem;
      margin-top: var(--full-space);
      border: transparent;
    }
    textarea {
      height: auto;
    }
    .submit-section {
      width: 20rem;
      text-align: center;
      margin: var(--full-space) auto 0;
      p {
        font-family: $font-bold;
        font-size: 1.6rem;
        color: var(--kabaddi-secondary-color);
        line-height: 1.1;
      }
    }
    .btn-submit {
      position: relative;
      background-color: var(--kabaddi-primary-color);
      width: 100%;
      height: 4.5rem;
      font-size: 1.4rem;
      font-family: $font-bold;
      color: var(--black-color);
      border-radius: 0;
      @include thumb-clip(70%, 94%);
      &::before,
      ::after {
        content: "";
        display: block;
        height: 0;
        @include position(null, 0, null, null);
      }

      &::before {
        bottom: 0;
        right: 0;
        border-left: 0.08rem solid transparent;
        border-top: 0.08rem solid transparent;
        border-right: 0.08rem solid var(--white-color);
        border-bottom: 0.08rem solid var(--white-color);
        transform: rotate(0deg);
      }
    }
  }
  .info-button {
    position: relative;
    background: var(--primary-color);
    width: 100%;
    height: 4.5rem;
    margin-top: calc(3 * var(--full-space));
    color: var(--white-color);
    font-size: 1.2rem;
    font-family: $font-bold;
    @include flex-config(flex, null, center, center);

    .info-text {
      &:before {
        content: "\e802";
        position: absolute;
        left: 7rem;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        font-family: $font-icon;
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-contact-us {
    .contact-us-body {
      margin: auto;
    }
    .contact-form {
      .left-form-wrapper {
        flex-basis: calc(50% - var(--full-space));
        max-width: calc(50% - var(--full-space));
      }

      .right-form-wrapper {
        flex-basis: calc(50% - var(--full-space));
        max-width: calc(50% - var(--full-space));
        @include flex-config(flex, column, space-between, null);
      }

      textarea {
        height: 13rem;
      }

      .btn-submit {
        @include thumb-clip(70%, 95%);
      }

      .submit-section {
        width: 100%;
        margin: calc(3 * var(--half-space)) 0;
        p {
          font-size: 1.4rem;
        }
      }
    }
    .info-button {
      .info-text {
        &::before {
          left: 28rem;
          transform: translateY(-37%);
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-min-width) {
  .waf-contact-us {
    .contact-us-body {
      padding: 0 calc(22 * var(--full-space));
      margin: auto;
    }
    .contact-form {
      textarea {
        height: 13rem;
      }
      .submit-section {
        width: 100%;
        margin: calc(3 * var(--half-space)) 0;
      }
    }
  }
}
